const technologies = [
  {
    name: "html",
    description: `The building block, 
			the first thing that you 
			learnt about web 
			development`,
    size: "normal",
  },
  {
    name: "css",
    description: `Need some makeup
			and some cool animations? 
			yes sir...`,
    size: "normal",
  },
  {
    name: "javascript",
    description: `The cousin of Java and
			the minor genius brother of
			TypeScript`,
    size: "medium",
  },
  {
    name: "sass",
    description: `CSS’ mother. Keeping 
			things clean and 
			organized. She knows
			you'll say thanks in the
			future`,
    size: "medium",
  },
  {
    name: "git",
    description: `The reason why you
			looked silly when you
			first upload your code
			to google drive`,
    size: "normal",
  },
  {
    name: "react",
    description: `Everything before packaged and tied up, with some instant magic inside`,
    size: "normal",
  },
];

const additionalTechnologies = [
  {
    name: "figma",
  },
  {
    name: "creativity",
  },
  {
    name: "rdb",
  },
];

export { technologies, additionalTechnologies };
